<template lang="">
    <div class="custom-container" v-if="home_featured_categories.length">
        <div class="section_title mt-5">
            <h4 class="text mb-lg-5 mb-3">Featured Categories</h4>
        </div>
        <div class="home_featured_categories">
            <div class="item" v-for="(category, index) in home_featured_categories" :key="category.id">
                <Link :href="`/products/${category.slug}`">
                    <img :src="load_image(category.image)" />
                    {{category.title}}
                </Link>
            </div>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
import { use_home_page_store } from '../../Store/home_page_store';
import { mapState } from 'pinia';

export default {
    computed:{
        ...mapState(use_home_page_store,[
            "home_featured_categories"
        ])
    },
}
</script>
<style lang="">

</style>
