<template>
    <div class="row">
        <div class="col-lg-4">
            <div>
                <product-image :product="product"></product-image>
            </div>
        </div>
        <div class="col-lg-8 ps-xl-4">
            <div class="product_top_basic_infos">

                <div class="left_info">
                    <div class="single-product-info">
                        <div class="single-product-info-top">
                            <h3 class="single-product-title">
                                {{ product.product_title }}
                            </h3>

                            <ul class="product_details_price_attributes">
                                <li>
                                    <span class="key">
                                        Price:
                                    </span>
                                    <span class="value">
                                        {{ number_format(product.final_price) }} ৳
                                    </span>
                                </li>

                                <li v-if="product.current_discount_price">
                                    <span class="key">
                                        Regular Price:
                                    </span>
                                    <span class="value">
                                        {{ number_format(product.current_price) }} ৳
                                    </span>
                                </li>

                                <li>
                                    <span class="key">
                                        Status:
                                    </span>
                                    <span class="value">
                                        <span v-if="product.is_in_stock">
                                            In stock
                                        </span>
                                        <span v-else>
                                            <a href="tel:+8801793199803">Call Us</a>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span class="key">
                                        Product Code:
                                    </span>
                                    <span class="value">
                                        {{ product.barcode }}
                                    </span>
                                </li>
                                <li>
                                    <span class="key">
                                        Brand:
                                    </span>
                                    <span class="value">
                                        {{ product.product_brand?.title }}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div class="quick_overview">
                            <div class="short_description" v-html="get_short_description(product.short_description)">
                            </div>
                        </div>
                    </div>

                    <div class="color_varients" v-if="product.color_qtys.length && product.price_type == 'single'">
                        <h6 class="product-title d-block">Select Color</h6>
                        <div class="color_varient_images">
                            <div class="single_color" v-for="(color, index) in product.color_qtys" :key="index">
                                <label @click="change_preview(load_image(color?.image?.url))"
                                    :for="`product_color_${color?.color?.id}`">
                                    <img :src="load_image(color?.image?.url)" />
                                    <input :checked="selected_color?.color?.id == color?.color?.id" type="radio"
                                        @change="selected_color = color; quantity = 1;" :name="`product_cart_color`"
                                        :id="`product_color_${color?.color?.id}`">
                                </label>
                            </div>
                        </div>
                        <h6 class="product-title d-block mt-3">
                            Available Quantity: {{ selected_color.qty }} Pcs
                        </h6>
                    </div>

                    <div v-if="selected_color.color_sizes && product.price_type == 'variant'">
                        <div class="color_varients">
                            <h6 class="product-title d-block">Select Color</h6>
                            <div class="color_varient_images">
                                <div class="single_color" v-for="(color, index) in product.color_size_prices"
                                    :key="index">
                                    <label @click="change_preview(load_image(color?.image?.url))"
                                        :for="`product_color_${color?.color?.id}`">
                                        <img :src="load_image(color?.image?.url)" />
                                        <input :checked="selected_color?.color?.id == color?.color?.id" type="radio"
                                            @change="selected_color = color; quantity = 1;" :name="`product_cart_color`"
                                            :id="`product_color_${color?.color?.id}`">
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="available_sizes">
                            <h6 class="product-title d-block mt-3">Select Size</h6>
                            <table class="product_size_table table">
                                <thead>
                                    <tr>
                                        <th style="width: 10px;"></th>
                                        <th>Size</th>
                                        <th>Available</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @click="selected_size = size; quantity = 1;"
                                        v-for="(size, index) in selected_color.color_sizes" :key="index">
                                        <td>
                                            <input :checked="selected_size.size.id == size.size.id"
                                                :value="size.size.id" type="radio" name="product_cart_size">
                                        </td>
                                        <td>
                                            {{ size?.size?.title }}
                                        </td>
                                        <td>
                                            {{ size?.qty }}
                                        </td>
                                        <td>
                                            {{ number_format(Math.round(size?.varient_price?.price)) }} TK
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-if="check_is_in_stock()">
                        <div class="">
                            <h6 class="product-title d-block mt-3">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <button class="qty-minus" @click="AdujustQuantity('minus')"></button>
                                    <input class="qty-adj form-control" type="number" min="1" v-model="quantity" />
                                    <button class="qty-plus" @click="AdujustQuantity('plus')"></button>
                                </div>
                            </div>
                        </div>

                        <div class="product-buttons d-flex flex-wrap gap-2 mt-4">
                            <button @click="add_to_cart(product, +quantity);" onclick="openCart();"
                                class="btn btn-normal">
                                <i class="icon icon-shopping-cart"></i>
                                Add to cart

                                <span v-if="is_in_cart(product).status" class="d-none">
                                    {{ set_default_qty(is_in_cart(product).qty) }}
                                    ( {{ is_in_cart(product).qty }} )
                                </span>

                            </button>

                            <a @click="is_auth ? add_to_wish_list(product.product_id) : openAccount()"
                                class="btn px-4 btn-normal btn-outline add-to-wish tooltip-top"
                                data-tippy-content="Add to wishlist">
                                <i class="fa fa-heart" aria-hidden="true"></i>
                                Add to wish list
                            </a>

                        </div>
                    </div>

                    <div v-else>
                        <h4 class="text-black my-4">Stock Out</h4>
                        <a class="btn btn-normal" href="tel:+8801793199803">
                            <i class="icon icon-mobile"></i>
                            Call Us: +8801793199803
                        </a>
                    </div>
                </div>

                <div class="right_info">
                    <right-support-info/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ColorVarient from '../Components/ColorVarient.vue'
import CommonVarient from '../Components/CommonVarient.vue'
import ProductImage from '../Components/ProductImage.vue'

import { mapActions, mapState, mapWritableState } from "pinia";
import { common_store } from "../../../Store/common_store";
import { cart_store } from '../../../Store/cart_store';
import RightSupportInfo from './RightSupportInfo.vue';
import { auth_store } from '../../../Store/auth_store';

export default {
    components: { ProductImage, ColorVarient, CommonVarient, RightSupportInfo },
    props: {
        product: Object
    },
    data: () => ({
        quantity: 1,
    }),
    created: async function () {
    },
    mounted: function () {
        if (this.product?.color_size_prices?.length) {
            try {
                this.selected_color = this.product?.color_size_prices[0];
            } catch (error) {
                console.log(error);
            }
            try {
                this.selected_size = this.product?.color_size_prices[0]?.color_sizes[0];
            } catch (error) {
                console.log(error);
            }
        }

        if (this.product?.color_qtys?.length) {
            try {
                this.selected_color = this.product?.color_qtys[0];
            } catch (error) {
                console.log(error);
            }
        }
    },
    watch: {
        quantity: function (v) {
            this.add_to_cart(this.product, v);
        },
        carts: {
            handler: function(v){
                this.set_default_qty(this.is_in_cart(this.product).qty)
            },
            deep: true,
        }
    },
    methods: {
        ...mapActions(common_store, {
            add_to_wish_list: "add_to_wish_list",
            get_setting_value: "get_setting_value",
        }),

        ...mapActions(cart_store, [
            "add_to_cart",
            "is_in_cart",
        ]),

        AdujustQuantity: function (type) {
            if (type == "plus") {
                this.quantity++;
            } else {
                if (this.quantity > 1) {
                    this.quantity--;
                }
            }
        },

        openAccount() {
            document.getElementById("myAccount").classList.add('open-side');
        },

        set_default_qty: function (qty) {
            if(qty){
                this.quantity = qty;
            }
        },

        get_short_description: function (description) {
            try {
                let data = `<table>`;
                data += JSON.parse(description)
                    .filter(i => i.value && i.value != 'value')
                    .map(i => `<tr><td>${i.key ?? ''}</td><td>:</td><td>${i.value ?? ''}</td></tr>`)
                    .join('');
                data += "</table";
                return data;
            }
            catch (e) {
                console.log(e);
                return description;
            }
        },

        change_preview: function (url) {
            let el = document.querySelector('#image_viewer');
            if (el) {
                el.querySelector('a').href = url;
                el.querySelector('img').src = url;
            }
        },

        check_is_in_stock: function () {
            if (this.product.type == "fashion" && this.product.price_type == "variant") {
                return this.selected_size.qty > 0;
            }
            else if (this.product.type == "fashion" && this.product.price_type == "single") {
                return this.selected_color.qty > 0;
            } else {
                return this.is_in_stock > 0;
            }
        }

    },
    computed: {
        ...mapState(cart_store, [
            "carts"
        ]),
        ...mapWritableState(cart_store, [
            'selected_color',
            'selected_size',
        ]),
        ...mapState(auth_store, [
            'is_auth',
        ])
    }
}
</script>

<style scoped>
.short-description ul {
    display: grid;
    gap: 10px;
}

.short-description ul li {
    font-weight: bold;
}
</style>
