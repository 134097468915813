<template>
    <template v-if="product.product_image">
        <div class="image_viewer" id="image_viewer">
            <a :href="load_image(`${imageUrl}`)" data-lightbox="product-set" :data-title="`Product image`">
                <img :src="load_image(imageUrl)" :alt="product.title" class="img-fluid image_zoom_cls-0" />
            </a>
        </div>
        <div class="product_related_image_box">
            <ul ref="product_related_images" v-if="product.product_images?.length" class="mt-3 product_related_images">
                <li v-for="(image) in product.product_images" :key="image.id">
                    <a :href="load_image(`${image.url}`)" data-lightbox="product-set" @click.prevent="() => ''">
                        <img class="border p-1 mx-1  c-pointer" @click="imageUrl = load_image(image.url)"
                            :src="load_image(`${image.url}`)" :alt="product.title" />
                    </a>
                </li>
            </ul>
            <div class="navigation_left" @click="scroll('left')">
                <i class="fa fa-angle-left"></i>
            </div>
            <div class="navigation_right" @click="scroll('right')">
                <i class="fa fa-angle-right"></i>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    props: {
        product: Object
    },
    data: () => ({
        imageUrl: '',
    }),
    created() {
        this.imageUrl = this.load_image(this.product.product_images?.length ? this.product.product_images[0].url : '');
    },
    mounted: function () {
        this.$refs.product_related_images.addEventListener('wheel', (event) => {
            event.preventDefault();
            if (event.deltaY < 0) {
                this.scroll('right');
            } else if (event.deltaY > 0) {
                this.scroll('left');
            }
        });
    },
    watch: {
        product() {
            this.imageUrl = this.load_image(this.product.product_images?.length ? this.product.product_images[0].url : '');
        }
    },
    methods: {
        scroll: function (position = 'left') {
            this.$refs.product_related_images.scrollBy({
                left: position == 'left' ? -100 : 100,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style></style>
