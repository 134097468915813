<template lang="">
    <section class="py-5">
        <div class="custom-container">
            <div v-for="category in categories" :key="category.id">
                <div class="shop_category_section_heading mt-4 ">
                    <div v-if="category?.banner_image">
                        <img :src="load_image(category?.banner_image)" class="w-100"/>
                    </div>
                    <h2 class="section_heading">
                        <div>
                            {{ category?.title }} Products
                        </div>

                        <Link class="section_heading_button" :href="`/products/${category?.slug}`">
                            More {{ category?.title }} Products
                            <i class="fa fa-angle-right"></i>
                        </Link>
                    </h2>
                </div>
                <div class="mb-5">
                    <div class="product_list">
                        <template v-for="product in category.products" :key="product.id">
                            <ProductItem :product="product"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapWritableState } from 'pinia';
import ProductItem from '../../../Components/ProductItem.vue';
import { use_home_page_store } from "../Store/home_page_store";
export default {
    components: {
        ProductItem,
    },
    props: [],
    computed: {
        ...mapWritableState(use_home_page_store, {
            categories: 'home_featured_category_products',
            category_group_slug: 'category_group_slug',
        })
    }
}
</script>
<style lang="">

</style>
