<template lang="">
    <div class="chat_message_box" v-if="show_chat_box">
        <div class="chat_message_header" @click="close_chat_boxes">
            <i class="fa fa-arrow-left"></i>
            <span>
                Live support
            </span>
        </div>
        <div class="messages" :ref="'message_list'">
            <template v-for="(i, index) in messages.data" :key="index">
                <div class="message_item" :class="{reply: i.is_sender == 0, sent: i.is_sender == 1}">
                    <div class="text">
                        {{ i.message }}
                    </div>
                </div>
            </template>
        </div>
        <div class="messeging_box">
            <div class="input_box">
                <input type="text" @keyup.enter="send_message($refs.message_text)" :ref="'message_text'">
            </div>
            <div @click.prevent="send_message($refs.message_text)" class="send_button btn">
                <i class="fa fa-paper-plane"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapWritableState } from 'pinia';
import { chat_bot_store } from '../store/chat_bot_store';
import { ref } from 'vue';
export default {
    mounted: function(){
        this.$watch('show_chat_box', function(){
            setTimeout(this.scroll_to_down, 500);
        })
    },
    methods: {
        ...mapActions(chat_bot_store, [
            'close_chat_boxes',
            'send_message',
            'scroll_to_down',
        ]),

    },
    computed: {
        ...mapWritableState(chat_bot_store, [
            'show_chat_box',
            'messages',
        ])
    }
}
</script>
<style lang="">

</style>
