<template>
    <layout>
        <Head>
            <title>
                {{ event?.title }}
            </title>
        </Head>

        <section v-if="!Object.keys(product_initial_data).length">
            <div class="custom-container">
                <img src="/frontend/images/product_skeleton.png" class="w-100" alt="product-loading">
            </div>
        </section>

        <section v-else>
            <template v-if="product_initial_data.type == 'medicine'">
                <medicine-product></medicine-product>
            </template>
            <template v-if="product_initial_data.type != 'medicine'">
                <general-product></general-product>
            </template>
        </section>

        <SimlarCategoryProducts />
        <RecentVisitedProducts />
    </layout>
</template>

<script>
import set_similar_category_products from "./Store/set_similar_category_products.js"
import set_recent_visited_products from "./Store/set_recent_visited_products.js";
import Layout from "../../Shared/Layout.vue";
import BreadCumb from '../../Components/BreadCumb.vue';
import MedicineProduct from './MedicineProduct.vue';
import GeneralProduct from './GeneralProduct.vue';
import { useProductDetailsStore } from './Store/product_details_store.js';
import { mapActions, mapState, mapWritableState } from 'pinia';
import TopProducts from './Components/TopProducts.vue';
import { cart_store } from '../../Store/cart_store';
import RecentVisitedProducts from "./Components/RecentVisitedProducts.vue";
import SimlarCategoryProducts from "./Components/SimlarCategoryProducts.vue";

export default {
    components: {
        MedicineProduct,
        GeneralProduct,
        BreadCumb,
        Layout,
        TopProducts,
        RecentVisitedProducts,
        SimlarCategoryProducts,
    },
    props: {
        slug: String,
        product: Object,
        event: Object,
    },
    data: () => ({
        preloader: true,
        bread_cumb: [],
    }),
    created: function () {
        this.store_slug = this.slug;
        this.product_details = this.product_initial_data = this.product;
    },
    mounted: function () {
        setTimeout(()=>this.init_additional_data(), 2000);
    },
    methods: {
        ...mapActions(useProductDetailsStore, {
            get_all_question_and_answers: "get_all_question_and_answers",
        }),

        set_recent_visited_products,
        set_similar_category_products,

        init_additional_data: function () {
            this.get_all_question_and_answers(this.slug);
            this.preloader = false;
            this.set_recent_visited_products(this.product);
            this.set_similar_category_products();
        },

    },

    computed: {
        ...mapState(useProductDetailsStore, {
            top_products: "top_products",
        }),

        ...mapWritableState(useProductDetailsStore, {
            product_details: 'product_details',
            product_initial_data: 'product_initial_data',
            store_slug: 'slug',
        }),

        ...mapState(cart_store, [
            "carts"
        ])

    }


};
</script>

<style scoped>
/* Add your styles here */
</style>
