<template lang="">
    <header>
        <div class="container">
            <div class="logo">
                <img src="/logo.png" />
            </div>
        </div>
    </header>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>
