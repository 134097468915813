<template>
    <Head>
        <Title>
            {{ event.title }}
        </Title>
    </Head>
    <div class="payment-status mt-3 p-d-none" v-if="status && status == 'success'">
        <div class="d-flex align-items-center justify-content-center icon-part">
            <i class="checkmark">✓</i>
        </div>
        <h1>Success</h1>
        <p class="fw-bold">Order completed successfully.</p>
    </div>

    <section class="mt-3">
        <order-invoice :order_info="data"></order-invoice>
    </section>

</template>

<script>
import OrderInvoice from '../../../Components/Invoice/OrderInvoice.vue';
export default {
    components: { OrderInvoice },
    props: [
        'event',
        'data',
        'status',
    ],
    data: () => ({
        order_id: null,
        order_info: {}
    }),
    mounted: async function () {
        // await this.get_single_order_details();
    },
    methods: {
        get_single_order_details: async function () {
            if(!this.order_id){
                return;
            }
            let response = await window.privateAxios('/get-single-order-details/' + this.order_id);
            this.order_info = response.data;
        },
    },

};
</script>
