<template>
    <h1>all categories</h1>
</template>

<script>
export default {

}
</script>

<style></style>
