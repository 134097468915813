<template>
    <Head>
        <Title>
            {{ event.title }}
        </Title>
    </Head>
    <ProfileLayout :bread_cumb="bread_cumb">
        <div class="dashboard">
            <div class="page-title">
                <h2>
                    Wish List
                </h2>
                <hr>
            </div>
            <div class="box-account box-info">
                <div class="table-responsive">
                    <template v-if="all_wish_list_data.length > 0">
                        <table class="product_info_table table table-bordered mb-0">
                            <tbody>

                                <tr v-for="item in all_wish_list_data" :key="item.id">
                                    <td>
                                        <img :src="`/${item.product?.product_image?.url}`" alt="">
                                        <span style="padding-left: 20px;">
                                            {{ item.product?.title }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="price">
                                            {{ item.product?.final_price }} ৳
                                        </div>
                                    </td>
                                    <td style="width: 200px;">
                                        <div class="action d-flex gap-2">
                                            <Link :href="`/${item.product?.slug}`" class="btn btn-primary text-light">
                                                <i class="fa fa-shopping-cart"></i>
                                                Buy
                                            </Link>
                                            <a @click="remove_wish_list_item(item.id)"
                                                class="btn btn-danger text-light">
                                                <i class="fa fa-trash"></i>
                                                Remove
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else-if="!loaded">
                        <div class="text-center py-5">
                            <img src="/loader.gif" style="height: 30px;" />
                        </div>
                    </template>
                    <div v-else>
                        <div class="text-center py-5">
                            Empty wish list.
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ProfileLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { common_store } from "../../../Store/common_store";
import ProfileLayout from "../shared/ProfileLayout.vue";
export default {
    components: { ProfileLayout },
    props: ['event'],
    data: () => ({
        loaded: false,
        bread_cumb: [
            {
                title: 'profile',
                url: '/profile',
                active: false,
            },
            {
                title: 'wish list',
                url: '/profile/wish-list',
                active: true,
            },
        ]
    }),
    mounted: async function () {
        await this.get_all_wish_list_items();
        this.loaded = true;
    },
    methods: {
        ...mapActions(common_store, {
            get_all_wish_list_items: "get_all_wish_list_items",
            remove_wish_list_item: "remove_wish_list_item",
            get_price: "get_price",
        }),
    },

    computed: {
        ...mapState(common_store, {
            all_wish_list_data: "all_wish_list_data",

        }),
    },
};
</script>

<style></style>
