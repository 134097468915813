<template>
    <div class="my-3" v-if="product_reviews?.data?.length">
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <h3 class="alert-secondary px-2 py-2 mb-1">Customer Reviews</h3>
                </div>
                <div v-for="review in product_reviews?.data" :key="review.id" class="card review-card mb-3">
                    <div class="row g-0">
                        <div class="col-md-12">
                            <div class="card-body">
                                <h6 class="card-subtitle mb-2 text-muted">
                                    <span>Reviewed by</span>
                                    <span class="fw-bold mx-2"> {{ review.user?.name }}
                                    </span> on {{ new Date(review.created_at).toDateString() }}
                                </h6>
                                <p class="card-text">{{ review.description }}</p>
                                <div class="rating">
                                    <span v-for="n in 5" :key="n" class="star"
                                        :class="{ filled: n <= review.rating }">&#9733;</span>
                                </div>
                                <div class="additional-images mt-3">
                                    <a v-for="(imageItem, index) in review.product_review_images" :key="index"
                                        :href="load_image(`${imageItem.image}`)"
                                        data-lightbox="image-set"
                                        :data-title="`Additional image ${index + 1}`">

                                        <img
                                            ref="lazyImage"
                                            :src="load_image('loader.gif')"
                                            :data-src="load_image(`${imageItem.image}`, true, true)"
                                            class="img-thumbnail product-image-small"
                                            :alt="`Additional image ${index + 1}`">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProductDetailsStore } from '../Store/product_details_store';
export default {
    props: {
        product: Object
    },
    data: () => ({}),
    mounted: function(){
        setTimeout(this.get_reviews,2000);
    },
    watch: {
        product_reviews: {
            handler: function(v){
                if(!v?.data?.length) return;
                let load = this.lazyLoadImages;
                setTimeout(load, 1000);
            },
            deep: true,
        }
    },
    computed: {
        ...mapState(useProductDetailsStore, [
            'product_reviews'
        ]),
    },
    methods: {
        ...mapActions(useProductDetailsStore, [
            'get_reviews'
        ]),
        lazyLoadImages: function() {
            const imageElements = this.$refs.lazyImage;

            const options = {
                root: null, // viewport
                rootMargin: "0px",
                threshold: 0.1, // Trigger when 10% of the image is visible
            };

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const imgElement = entry.target;
                        const dataSrc = imgElement.getAttribute("data-src");

                        if (dataSrc) {
                            imgElement.src = dataSrc; // Load the image
                            observer.unobserve(imgElement); // Stop observing once loaded
                            imgElement.classList.remove('lazy_image_opacity');
                        }
                    }
                });
            }, options);

            imageElements.forEach(e=>observer.observe(e));
        },
    }
};
</script>

<style scoped>
.review-card {
    margin-bottom: 20px;
}

.rating {
    color: gold;
}

.product-image {
    max-width: 150px;
    margin-right: 20px;
}

.product-image-small {
    max-width: 70px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.star {
    font-size: 1.5em;
    color: lightgray;
}

.star.filled {
    color: gold;
}
</style>
