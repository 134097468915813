<template>
    <div class="invoice-box" v-if="order_info?.order_products?.length && Object.keys(order_info).length">
        <table cellpadding="0" cellspacing="0">
            <thead>
                <tr class="top">
                    <td colspan="4">
                        <table>
                            <tr class="top_header_row">
                                <td>
                                    <div>
                                        <img :src="`${load_image(get_setting_value('header_logo'))}`" style="width: 100%; max-width: 150px" />
                                        <div style="margin-top: 10px;">
                                            <b>
                                                {{ get_setting_value('site_name') }}
                                            </b>
                                        </div>
                                        {{ get_setting_value('address') }}
                                    </div>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <div class="qr_code_body">
                                        <img class="center_logo" :src="`/fabicon.ico`" />
                                        <div class="qr_code" id="qr_code"></div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="information">
                    <td colspan="1">
                        <div>
                            Invoice #: <span class="fw-bold"> {{ order_info?.order_id }}</span><br />
                            Date : {{ new Date(order_info?.created_at).toDateString() }}<br />
                            Payment method : {{ order_info?.payment_method }}<br />
                            Payment status : {{ order_info?.paid_status == 'cod'? 'cash on delivery' : order_info?.paid_status }}<br />
                        </div>
                    </td>

                    <td colspan="3">
                        <div>
                            <h4>Delivery Information</h4>
                            {{ get_address_data('user_name') }}.<br />
                            <span v-if="get_address_data('email')">
                                {{ get_address_data('email') }}<br />
                            </span>
                            {{ get_address_data('phone') }}
                        </div>
                        <div class="delivery_address">
                            {{ get_address_data('address') }}.<br />
                            {{ get_address_data('station_name') }} ,
                            {{ get_address_data('district_name') }},
                            {{ get_address_data('division_name') }} <br>
                        </div>
                    </td>
                </tr>
                <tr class="heading ">
                    <th>Item</th>
                    <th style="width: 130px;">Quantity</th>
                    <th style="width: 130px;">Unit Price</th>
                    <th style="width: 130px;" class="text-end">Price</th>
                </tr>
            </thead>

            <tbody>
                <tr class="item" v-for="item in order_info.order_products" :key="item.id">

                    <td>
                        <div class="product_title">
                            <b>
                                {{ item.product_name }}
                            </b>
                        </div>
                        <div v-if="get_varient_data(item.varients,'color')" class="product_title">
                            color: {{ get_varient_data(item.varients,'color') }}
                        </div>
                        <div v-if="get_varient_data(item.varients,'size')" class="product_title">
                            size: {{ get_varient_data(item.varients,'size') }}
                        </div>
                    </td>
                    <td>{{ item.qty }}</td>
                    <td style="white-space: nowrap;">{{ print_number(item.price) }} ৳</td>
                    <td style="white-space: nowrap;">
                        {{ print_number( item.price * item.qty ) }} ৳
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr class="total">
                    <td colspan="3" class="text-end pe-4">
                        Sub total
                    </td>
                    <td style="white-space: nowrap;"> {{ print_number(order_info?.subtotal) }} ৳</td>
                </tr>
                <tr class="total">
                    <td colspan="3" class="text-end pe-4">Delivery charge</td>
                    <td style="white-space: nowrap;"> {{ print_number(order_info?.delivery_charge) }} ৳</td>
                </tr>
                <tr class="total">
                    <td colspan="3" class="text-end pe-4">Total:</td>
                    <td style="white-space: nowrap;">{{ print_number(order_info?.total) }} ৳</td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="text-center mt-5 pt-5">
                            Thank you for your order <br>
                            Please visit for products.
                            <a :href="location.origin">
                                {{ location.origin }}
                            </a>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div class="text-center p-d-none">
        <button id="printBtn" @click="printInvoice"
            class="btn btn-success mt-3 fw-bold text-white  py-2 px-5 my-5">Print
            Invoice
        </button>
        <Link href="/" class="btn btn-info mt-3 fw-bold text-white ms-2 ml-2 py-2 px-5 my-5">
            Back To Shopping
        </Link>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { common_store } from "../../Store/common_store";
export default {
    props: {
        order_info: Object,
        default: {},
    },
    created: async function () {
        // await this.get_all_website_settings();
    },
    mounted: function(){
        this.generate_qr_code();
    },
    data: () => ({
        location: window.location,
    }),
    methods: {
        ...mapActions(common_store, {
            get_setting_value: "get_setting_value",
            get_all_website_settings: "get_all_website_settings",
        }),
        printInvoice() {
            window.print();
        },
        generate_qr_code: function(){
            var url = location.href;
            let el = document.getElementById("qr_code");
            if(!el){
                return setTimeout(this.generate_qr_code, 1000);
            }
            if(el && !el.querySelector('img')){
                new window.QRCode(el, {
                    text: url,
                    width: 128,
                    height: 128
                });
            }
        },
        get_address_data: function(key='user_name'){
            let address = this.order_info.delivery_address_details;
            try {
                address = JSON.parse(address);
            } catch (error) {
                address = this.order_info.delivery_address_details;
            }
            if(address){
                return address[key];
            }
            return '';
        },

        get_varient_data: function(varients, key=''){
            try {
                varients = JSON.parse(varients);
            } catch (error) {
                varients = varients;
            }
            if(varients){
                return varients[key];
            }

            return '';
        },

        print_number: function(value){
            if(value && !isNaN(value)){
                return  this.number_format(Math.round(value))
            }
            return value;
        }
    },

}
</script>

