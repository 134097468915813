<template>
    <Head>
        <Title>
            {{ event.title }}
        </Title>
    </Head>
    <Layout>
        <div class="breadcrumb-main py-3">
            <div class="custom-container">
                <BreadCumb :bread_cumb="bread_cumb" />
            </div>
        </div>

        <section class="login-page section-big-py-space b-g-light">
            <div class="custom-container">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-8">
                        Login Success
                    </div>
                </div>

            </div>
        </section>

    </Layout>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { auth_store } from "../../Store/auth_store.js";
import BreadCumb from "../../Components/BreadCumb.vue";
import Layout from "../../Shared/Layout.vue";
import { router } from '@inertiajs/vue3'
export default {
    components: { Layout, BreadCumb },
    props: ['event', 'data'],
    data: () => ({
        bread_cumb: [
            {
                title: 'google auth',
                url: '/login',
                active: true,
            },
        ],

    }),
    beforeCreate: function(){
        // if (this.is_auth) {
        //     window.location.href = "/profile";
        // }
    },
    created: function(){
        localStorage.setItem("token", this.data?.access_token);
        window.location.href = "/profile";
    },
    mounted: function(){
        // if (this.is_auth) {
        //     window.location.href = "/profile";
        // }
    },
    methods: {

    },
    computed: {
        ...mapWritableState(auth_store, {
            is_auth: "is_auth",
            auth_info: "auth_info",
        }),
    },
};
</script>

<style scoped>
.password-icon {
    position: relative;
}

.password-icon i {
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
}
</style>
