<template lang="">
    <div class="product-description-wrapper" style="height: auto;">
        <div class="product-delivery-and-return">
            <ul class="features">
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <i class="fa fa-headphones" aria-hidden="true"></i>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">Support</h3>
                            <span class="feature-desc">
                                Saturday - Friday <br/>
                                09 AM - 10 PM.
                            </span>
                        </div>
                    </div>
                </li>
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <a target="_blank" :href="`tel:${get_setting_value('phone_numbers')}`">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">Hotline</h3>
                            <a target="_blank" :href="`tel:${get_setting_value('phone_numbers')}`" class="feature-desc">
                                {{ get_setting_value('phone_numbers') }}
                            </a>
                        </div>
                    </div>
                </li>
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <a target="_blank" :href="`https://wa.me/${ get_setting_value('whatsapp') }`">
                                <i class="fa fa-whatsapp" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">Whatsapp</h3>
                            <a target="_blank" :href="`https://wa.me/${ get_setting_value('whatsapp') }`" class="feature-desc">
                                {{ get_setting_value('whatsapp') }}
                            </a>
                        </div>
                    </div>
                </li>
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <i class="fa fa-credit-card" aria-hidden="true"></i>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">Payment</h3>
                            <span class="feature-desc">
                                Cash on Delivery | Online Payment
                            </span>
                        </div>
                    </div>
                </li>
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <i class="icon icon-wallet" style="color: #0e4194;" aria-hidden="true"></i>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">EMI</h3>
                            <span class="feature-desc">
                                We will provide 12th month
                                easy loan for all our item
                            </span>
                        </div>
                    </div>
                </li>
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <i class="fa fa-truck" aria-hidden="true"></i>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">Shipping Cost</h3>
                            <span class="feature-desc">
                                Inside Dhaka 60tk |
                                Outside Dhaka
                                120tk
                            </span>
                        </div>
                    </div>
                </li>
                <li class="feature">
                    <div class="feature-inner">
                        <div class="feature-thumbnail">
                            <i class="fa fa-repeat" aria-hidden="true"></i>
                        </div>
                        <div class="feature-info">
                            <h3 class="feature-title">Warranty Policy</h3>
                            <span class="feature-desc">
                                <a href="/returns-exchanges" target="_blank">Read More</a>
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapActions, mapWritableState } from 'pinia';
import { common_store } from '../../../Store/common_store';
export default {
    methods: {
        ...mapActions(common_store, [
            'get_setting_value'
        ])
    }
}
</script>
<style lang="">

</style>
