import "../frontend/plugins/sweet_alert.js";
import "../frontend/plugins/axios_setup.js";
import "../frontend/plugins/helper_functions.js";

import { createApp, h } from "vue";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import { createPinia, mapWritableState } from "pinia";
import { common_store } from "./Store/common_store.js";
import { auth_store } from "./Store/auth_store.js";
import { cart_store } from "./Store/cart_store.js";
import { use_home_page_store } from "./Pages/Home/Store/home_page_store.js";
import axios from "axios";

window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
const pinia = createPinia();

window.page_data = () => {
    try {
        return JSON.parse(document.querySelector('#app').dataset.page);
    } catch (error) {
        return {};
    }
};

window.set_default_data = async function () {
    let store_common = mapWritableState(common_store, [
        'website_settings_data',
    ]);

    let auth_store_common = mapWritableState(auth_store, [
        'auth_info',
        'is_auth',
        'role',
    ]);

    let cart_store_state = mapWritableState(cart_store, [
        "carts",
    ])

    let home_page_store = mapWritableState(use_home_page_store, [
        "parent_categories",
        "all_modal_category_groups",
    ])

    let settings = window.page_data().props?.settings;
    let auth_store_data = window.page_data().props?.auth;
    let cart_data = window.page_data().props?.all_cart_data;
    let all_category_parents = window.page_data().props?.all_category_parents;
    let all_modal_category_groups = window.page_data().props?.all_category_groups;

    store_common.website_settings_data.set(settings);
    auth_store_common.auth_info.set(auth_store_data);
    home_page_store.parent_categories.set(all_category_parents);
    home_page_store.all_modal_category_groups.set(all_modal_category_groups);

    if (auth_store_data) {
        auth_store_common.is_auth.set(true);
        auth_store_common.role.set(auth_store_data.role);
    }

    let local_carts = [];
    if (localStorage.getItem('carts')) {
        local_carts = JSON.parse(localStorage.getItem('carts'));
    }

    if (!auth_store_data) {
        cart_store_state.carts.set(local_carts);
    } else {
        function getUniqueById(arr) {
            const unique = new Map();
            arr.forEach(item => {
                if (!unique.has(item.id)) {
                    unique.set(item.id, item);
                }
            });
            return Array.from(unique.values());
        }
        let temp = [...local_carts, cart_data];
        temp = getUniqueById(temp);
        if(temp.length && temp[0].qty){
            cart_store_state.carts.set(temp);
        }
    }

    let auth_stores = auth_store();
    let is_auth = await auth_stores.check_is_auth();
    if (is_auth?.data?.slug && !window.page_data().props?.auth?.slug) {
        axios.post(location.origin + '/custom-auth', { slug: is_auth.data.slug });
        // window.location.reload();
    } else if (window.page_data().props?.auth?.slug && !auth_stores.is_auth) {
        auth_stores.create_auth_token();
    }

    return {
        auth_info: auth_store_data,
    }
}

createInertiaApp({
    // title: title => title ? `${title} - Ping CRM` : 'Ping CRM',
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({
            render: () => h(App, props),
        });
        app.use(pinia);

        let res = window.set_default_data();

        app.config.globalProperties.auth_info = res.auth_info;
        app.config.globalProperties.load_image = window.load_image;
        app.config.globalProperties.number_format = function (number = 0) {
            try {
                return new Intl.NumberFormat().format(number)
            } catch (error) {
                return number;
            }
        }
        app.use(plugin);
        app.component("Link", Link);
        app.component('Head', Head);
        app.mount(el);
    },

});


