<template>
    <div class="product-core-info-list">
        <span class="p-core-info-list-title">Color</span>
        <div class="product-color-varient display-flex-center">
            <label class="single-color-varient" id="color-varient-1" for="flexRadioDefault1">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
            </label>
            <label class="single-color-varient" id="color-varient-2" for="flexRadioDefault2">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
            </label>
            <label class="single-color-varient" id="color-varient-3" for="flexRadioDefault3">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
            </label>
            <label class="single-color-varient" id="color-varient-4" for="flexRadioDefault4">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
            </label>
            <label class="single-color-varient" id="color-varient-5" for="flexRadioDefault5">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
            </label>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>
