<template>

    <div class="main-theme-slider">
        <carousel :items-to-show="1" :transition="2000" :pauseAutoplayOnHover="true" :wrapAround="true" :autoplay="3000">
            <slide v-for="slider in 12" :key="slider.id">
                <div class="main-slider-banner">
                    <img :src="`${load_image(`uploads/banner_main/${slider}.jpg`, true)}`" alt="Etek Enterprise Bangladesh"/>
                </div>
            </slide>
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>
    </div>
</template>
<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { mapState } from 'pinia';
export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },

    methods: {

    },

    computed: {

    },

};
</script>
