<template lang="">
    <Layout>
        <Head>
            <title>{{ event?.title || "Etek Enterprise" }}</title>
        </Head>

        <main id="main_page">
            <section class="main_page_banner">
                <div class="custom-container">
                    <!-- <img :src="load_image('uploads/banner_main/1.jpg')" alt="etek banner" /> -->
                    <Slider/>
                </div>
            </section>

            <section>
                <div class="custom-container">
                    <p class="greeting-text">
                        Welcome to <strong>etek.com.bd</strong>, your one-stop destination for everything you need!
                        From fashion to electronics, home appliances to vehicles, we've got it all.
                        Explore our vast range of products, enjoy exclusive offers,
                        and experience seamless shopping right at your fingertips. Whether you're
                        looking for the latest gadgets, everyday essentials, or something special,
                        we have you covered. <br/>
                        Thank you for choosing <strong>etek.com.bd</strong>, where quality meets convenience!
                    </p>
                </div>
            </section>

            <section class="main_categories_heading">
                <div class="custom-container">
                    <h2 class="text-center">
                        Visit our shops and choose your products, at
                        <strong>
                            etek.com.bd
                        </strong>
                    </h2>
                </div>
            </section>

            <section class="main_categories_list">
                <div class="custom-container">
                    <Categories />
                </div>
            </section>

            <section class="main_categories_heading">
                <div class="custom-container">
                    <h2 class="text-center">
                        Visit our popular brands products, at
                        <strong>
                            etek.com.bd
                        </strong>
                    </h2>
                </div>
            </section>

            <section class="main_categories_list">
                <div class="custom-container">
                    <Brands />
                </div>
            </section>

        </main>

    </Layout>
</template>
<script>
import { mapWritableState } from "pinia";
import Layout from "../../Shared/Layout.vue";
import Nav from './components/Nav.vue';
import Slider from './components/Slider.vue';
import Categories from './components/Categories.vue';
import Brands from './components/Brands.vue';
import { home_main_store } from "./store/home_main_store";

export default {
    components: {
        Nav,
        Layout,
        Slider,
        Categories,
        Brands,
    },
    props: [
        'events',
        'categories',
        'brands',
    ],
    mounted: function(){
        this.all_categories = this.categories;
        this.all_brands = this.brands;
    },
    computed: {
        ...mapWritableState(home_main_store, {
            all_categories: 'categories',
            all_brands: 'brands',
        }),
    }
}
</script>
<style lang="">

</style>
