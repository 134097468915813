<template lang="">
    <div class="start_chat_popup" v-if="show_start_popup">
        <div class="close_popup" @click="show_start_popup = false;">
            <i class="fa fa-angle-down"></i>
        </div>
        <h4>
            Hello friend! 👋
        </h4>
        <p>
            I am Your digital assistant. How can I help you today?
        </p>

        <div v-if="is_auth">
            <button @click.prevent="start_show_chat_box" class="start_chat_button">
                <i class="fa fa-message"></i>
                <span>
                    Start chat
                </span>
            </button>
        </div>

        <div v-else>
            <button v-if="!show_start_chat_form"
                @click.prevent="show_start_chat_form = true" class="start_chat_button">
                <i class="fa fa-message"></i>
                <span>
                    Chat with support
                </span>
            </button>

            <div v-else class="chart_start_form">
                <form @submit.prevent="()=>''">
                    <div>
                        <label>Full name</label>
                        <input placeholder="Full name" />
                    </div>
                    <div>
                        <label>Mobile number</label>
                        <input placeholder="Mobile number" />
                    </div>
                    <button @click.prevent="start_show_chat_box" class="start_chat_button">
                        <i class="fa fa-message"></i>
                        <span>
                            Start chat
                        </span>
                    </button>
                    <div class="text-center mt-2">
                        <a href="/google-login" class="login_with_google_button">
                            <img :src="load_image('icons/google.png')">
                            <span>
                                continue with google
                            </span>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapWritableState } from 'pinia';
import { chat_bot_store } from '../store/chat_bot_store';
import { auth_store } from '../../../Store/auth_store';
export default {
    methods: {
        ...mapActions(chat_bot_store, [
            'start_show_chat_box',
        ])
    },
    computed: {
        ...mapWritableState(chat_bot_store, [
            'show_start_popup',
            'show_start_chat_form',
        ]),
        ...mapWritableState(auth_store, [
            'is_auth'
        ])
    }
}
</script>
<style lang="">

</style>
