<template>
    <Head>
        <Title>
            {{ event.title }}
        </Title>
    </Head>
    <div>
        <h1>all brands</h1>
    </div>
</template>

<script>
export default {
    props: ['event'],
}
</script>

<style></style>
