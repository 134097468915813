
import { defineStore } from "pinia";

export const check_out_store = defineStore("cart_store", {
    state: () => ({
        is_outside_dhaka: 0,
    }),
    getters: {

    },
    actions: {

    },
});
