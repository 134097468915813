import { mapWritableState } from "pinia";
import { useProductDetailsStore } from "./product_details_store";

function set_recent_visited_products(product = null) {
    let store = mapWritableState(useProductDetailsStore, [
        'recent_visited_products',
    ]);
    let products = localStorage.getItem('recent_visited_products') || [];
    if (product && Object.keys(product).length && products.length) {
        try {
            products = JSON.parse(products);
            products = products
                .filter(i => i.product_id != product.product_id)
                .filter((i, index) => {
                    if (index < 5) return i;
                });
        } catch (e) {
            products = [];
            console.log(e);
        }
    }

    products.unshift(product);
    store.recent_visited_products.set(products);
    localStorage.setItem('recent_visited_products', JSON.stringify(products));

}

export default set_recent_visited_products;
