<template>
    <Head>
        <Title>
            {{ event.title }}
        </Title>
    </Head>
    <Layout>
        <section style="height: calc(100vh - 67px); display: grid; align-items: center;">
            <div class="container my-5">
                <div class="row text-center text-black">
                    <div class="col-lg-5">
                        <img :src="load_image('404.png')" class="img-fluid" />
                    </div>
                    <div class="col-lg-7">
                        <h1>
                            We Looked <br />
                            Everywhere
                        </h1>
                        <p class="my-4">
                           Look like this page is missing
                        </p>
                        <Link href="/products" class="btn btn-info"> Visit Shop</Link>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import { mapActions, mapState } from "pinia";
export default {
    components: { Layout },
    props:['event'],
    methods: {
    },
    computed: {
    },
};
</script>
