<template>
    <Head>
        <Title>
            {{ event.title }}
        </Title>
    </Head>
    <Layout>
        <div class="breadcrumb-main py-3">
            <div class="custom-container">
                <BreadCumb :bread_cumb="bread_cumb" />
            </div>
        </div>

        <section class="login-page section-big-py-space b-g-light">
            <div class="custom-container">
                <div class="row justify-content-center" v-if="is_register">
                    <div class="col-xl-5 col-lg-6 col-md-8">
                        <div class="theme-card">
                            <h3 class="text-center">Register</h3>
                            <form class="theme-form" @submit.prevent="registerFormHandler($event)" method="post">
                                <div class="form-group">
                                    <label>Full name</label>
                                    <input type="text" v-model="name" name="name" id="name" class="form-control"
                                        placeholder="Enter full name" />
                                </div>
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" name="phone_number" v-model="phone_number" id="phone_number" class="form-control"
                                        placeholder="Enter phone number" />
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" v-model="email" id="email" class="form-control"
                                        placeholder="Enter your email" />
                                </div>
                                <!-- <div class="form-group">
                                    <label>Password</label>
                                    <div class="password-icon">
                                        <input :type="type" name="password" id="password"
                                            class="form-control position-relative" placeholder="Enter your password" />
                                        <i class="fa fa-eye" :class="{ 'fa-eye-slash': type === 'password' }"
                                            @click="showPassword"></i>
                                    </div>
                                </div> -->
                                <button class="btn btn-normal">Register</button>
                                <!-- <a class="float-end txt-default mt-2" href="#">
                                    Forgot your password?
                                </a> -->
                            </form>

                            <p class="mt-3 d-flex align-items-center gap-2">
                                <span> Already have an account?</span>
                                <Link href="login" class="txt-default  d-block">Login</Link>
                            </p>

                            <div class="mt-5 text-center">
                                <a href="/google-login" class="login_with_google_button">
                                    <img :src="load_image('icons/google.png')" />
                                    <span>
                                        continue with google
                                    </span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row" v-if="is_otp_verify">
                    <div class="col-xl-4 col-lg-6 col-md-8 offset-xl-4 offset-lg-3 offset-md-2">
                        <div class="theme-card">
                            <h3 class="text-center">Verify otp</h3>
                            <div>
                                check your phone number and email
                            </div>
                            <form class="theme-form" @submit.prevent="OtpVerifyFormHandler($event)" method="post">
                                <div class="form-group">
                                    <label>Enter your otp</label>
                                    <input type="number" name="otp" id="otp" class="form-control">
                                </div>
                                <button class="btn btn-normal">Varify</button>
                                &nbsp;
                                &nbsp;
                                <button type="button" @click.prevent="is_otp_verify = false; is_register = true;" class="btn btn-normal btn-outline">Back</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </Layout>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { auth_store } from "../../Store/auth_store.js";
import BreadCumb from "../../Components/BreadCumb.vue";
import Layout from "../../Shared/Layout.vue";
import { router } from '@inertiajs/vue3'
export default {
    components: { Layout, BreadCumb },
    props: ['event'],
    data: () => ({
        bread_cumb: [
            {
                title: 'register',
                url: '/register',
                active: true,
            },
        ],
        type: "password",
        is_register: true,
        is_otp_verify: false,
        phone_number: "",
        name: "",
        email: "",

    }),
    beforeCreate: function(){
        if (this.is_auth) {
            window.location.href = "/profile";
        }
    },
    mounted: function(){
        if (this.is_auth) {
            window.location.href = "/profile";
        }
    },
    methods: {
        ...mapActions(auth_store, {
            check_is_auth: "check_is_auth",
            user_login: "user_login",
        }),
        registerFormHandler: async function (event) {
            let formData = new FormData(event.target);
            let response = await axios.post('/register', formData)
            if (response.data?.status === "success") {
                window.s_alert(response.data?.message);
                this.is_register = false;
                this.is_otp_verify = true;
                this.phone_number = response.data?.data?.phone_number;
            }
        },
        OtpVerifyFormHandler: async function (event) {
            let formData = new FormData(event.target);
            formData.append("phone_number", this.phone_number);
            formData.append("name", this.name);
            formData.append("email", this.email);
            let response = await axios.post('/verify-user-otp?type=customer', formData)
            if (response.data?.status === "success") {
                localStorage.setItem("token", response.data?.data?.access_token);
                window.s_alert(response.data?.message);
                this.auth_info = response.data?.data?.user;
                this.is_auth = true;
                // router.visit('/profile');
                setTimeout(() => {
                    window.s_alert("You are login successfully");
                    window.location.href = "/";
                }, 1000)

            }
        },
        formatBdPhoneNumber: function() {
            var phoneNumber = this.phone_number.replace(/\D/g, '');
            if (/^01[3-9]\d{8}$/.test(phoneNumber)) {
                return '880' + phoneNumber.substring(1);
            } else {
                return false;
            }
        },
        showPassword: function () {
            if (this.type === "password") {
                this.type = "text";
            } else {
                this.type = "password";
            }
        }
    },
    computed: {
        ...mapWritableState(auth_store, {
            is_auth: "is_auth",
            auth_info: "auth_info",
        }),
    },
};
</script>

<style scoped>
.password-icon {
    position: relative;
}

.password-icon i {
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
}
</style>
