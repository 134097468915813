import axios from "axios";
import { defineStore } from "pinia";

export const home_main_store = defineStore("home_main_store", {
    state: () => ({
        categories: [],
        brands: [],
    }),
    getters: {},
    actions: {

    }
});
