import { defineStore } from "pinia";


export const chat_bot_store = defineStore("chat_bot_store", {
    state: () => ({
        show_start_popup: false,
        show_chat_box: false,
        show_start_chat_form: false,
        messages: {
            data: [
                {
                    message: 'Assalamu alikum',
                    is_sender: 0,
                    replier_id: 1,
                    sender_id: 1,
                },
                {
                    message: 'Walikum Salam',
                    is_sender: 1,
                    replier_id: 1,
                    sender_id: 1,
                },
                {
                    message: 'Etek enterprise e apnake swagotom',
                    is_sender: 0,
                    replier_id: 1,
                    sender_id: 1,
                },
                {
                    message: 'Thank you',
                    is_sender: 1,
                    replier_id: 1,
                    sender_id: 1,
                },
            ]
        },
    }),

    actions: {
        close_chat_boxes: function () {
            this.show_start_popup = false;
            this.show_chat_box = false;
        },

        toggle_start_popup: function () {
            this.show_start_popup = !this.show_start_popup;
            if(this.show_chat_box){
                this.show_chat_box = false;
                this.show_start_popup = false;
            }
        },

        start_show_chat_box: function () {
            this.show_start_popup = false;
            this.show_chat_box = true;
        },

        send_message: function (el) {
            this.messages.data.push({
                message: el.value,
                is_sender: 1,
                replier_id: 1,
                sender_id: 1,
            });
            el.value = "";

            setTimeout(this.scroll_to_down, 200);
        },
        scroll_to_down: function(){
            let el = document.querySelector('.messages');
            el?.scrollTo({ top: el.scrollHeight, behavior: 'smooth' })
        }
    },
});
