<template>
    <div class="breadcrumb-main py-3">
        <div class="custom-container">
            <BreadCumb :bread_cumb="bread_cumb" />
        </div>
    </div>
    <section class="section-big-pt-space b-g-light">
        <div class="collection-wrapper">
            <div class="custom-container">
                <div class="container-fluid">
                    <ProductBasicInfo :product="product"></ProductBasicInfo>
                </div>
                <ProductBottomDetails :product="product"></ProductBottomDetails>
            </div>
        </div>
    </section>
</template>
<script>

import { useProductDetailsStore } from './Store/product_details_store.js';
import ProductBasicInfo from './Components/ProductBasicInfo.vue';
import ProductBottomDetails from './Components/ProductBottomDetails.vue';
import { mapWritableState } from 'pinia';
import BreadCumb from '../../Components/BreadCumb.vue';

export default {
    components: { BreadCumb, ProductBasicInfo, ProductBottomDetails, },
    data: () => ({
        loaded: false,
        bread_cumb: [],
    }),
    created: async function () {
        this.set_bread_cumb();
    },

    watch: {
        product: {
            handler: function (product) {
                if (Object.keys(product).length) {
                    this.set_bread_cumb();
                }
            },
            deep: true,
        }
    },

    methods: {
        set_bread_cumb: function () {
            let bread_cumb = [];

            this.product?.product_categories?.forEach(i => {
                bread_cumb.push({
                    title: i.title?.toLowerCase(),
                    url: '/' + i.slug,
                    active: false,
                })
            });

            bread_cumb.push({
                title: this.product.title,
                url: '/' + this.product.slug,
                active: true,
            });

            this.bread_cumb = bread_cumb;
        }
    },

    computed: {
        ...mapWritableState(useProductDetailsStore, {
            product: 'product_initial_data',
        })
    }


};
</script>

<style scoped>
/* Add your styles here */
</style>
