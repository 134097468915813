<template lang="">
    <div class="chat_bot">
        <div class="chat_bot_body">
            <div class="icon" @click="toggle_start_popup">
                <img :src="load_image('uploads/icons/customer_support.png')" />
            </div>

            <ChatBotStartPopup />

            <ChatBotMessageBox />
        </div>
    </div>
</template>
<script>
import ChatBotStartPopup from "./components/ChatBotStartPopup.vue"
import ChatBotMessageBox from "./components/ChatBotMessageBox.vue"
import { chat_bot_store } from "./store/chat_bot_store";
import { mapActions, mapWritableState } from "pinia";

export default {
    components: {
        ChatBotStartPopup,
        ChatBotMessageBox,
    },
    methods: {
        ...mapActions(chat_bot_store, [
            'toggle_start_popup',
        ]),
    },
    computed: {
        ...mapWritableState(chat_bot_store, [
            'show_start_popup',
        ])
    }
}
</script>

