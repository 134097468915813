<template lang="">
    <section class="b-g-light py-4" v-if="similar_category_products && similar_category_products?.data?.data?.length">
         <div class="custom-container">
             <h2>Similar products from {{ similar_category_products?.category?.title?.toLowerCase() }}</h2>
             <div class="mt-5">
                 <div class="product_list" :class="{product_left: similar_category_products.data?.data?.length < 5}">
                     <template v-for="product in similar_category_products.data.data" :key="product.slug">
                         <ProductItem :product="product" />
                     </template>
                 </div>
             </div>
         </div>
     </section>
 </template>
 <script>
 import { mapState } from 'pinia';
 import ProductItem from '../../../Components/ProductItem.vue';
 import { useProductDetailsStore } from '../Store/product_details_store';
 export default {
     components: {
         ProductItem,
     },
     computed: {
         ...mapState(useProductDetailsStore, [
             'similar_category_products'
         ]),
     }
 }
 </script>
 <style lang="">

 </style>
