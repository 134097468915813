import { mapWritableState } from "pinia";
import { useProductDetailsStore } from "./product_details_store";

async function set_similar_category_products(){
    let store = mapWritableState(useProductDetailsStore,[
        'similar_category_products',
        'product_initial_data',
    ]);

    let product_categories = store.product_initial_data.get().product_categories;
    if(product_categories && product_categories.length){
        let res = await window.publicAxios('/get-all-products-by-category-id/'+product_categories[0].slug);
        store.similar_category_products.set(res.data)
    }
}

export default set_similar_category_products;
