<template>
    <div class="product-core-info-list">
        <span class="p-core-info-list-title">Storage</span>
        <div class="product-storages display-flex-center">
            <div class="single-storage">
                <label for="flexRadioDefault7">
                    <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault7" />
                    8/256
                </label>
            </div>
            <div class="single-storage">
                <label for="flexRadioDefault8">
                    <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault8" />
                    8/512
                </label>
            </div>
            <div class="single-storage">
                <label for="flexRadioDefault9">
                    <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault9" />
                    8/512
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>
