<template>
    <div class="theme-card creative-card creative-inner related_product_card">
        <h5 class="title-border">Related Products</h5>
        <div class="plrb-0 b-g-white1 border-0">
            <template v-if="product.related_products?.length">
                <div class="media-banner-box" v-for="item in product.related_products" :key="item.slug">
                    <LeftProductItem :product="item" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'pinia';
import { common_store } from '../../../Store/common_store';
import LeftProductItem from '../../../Components/LeftProductItem.vue';
export default {
    props: {
        product: Object
    },
    components: {
        LeftProductItem,
    },
    methods: {
        ...mapActions(common_store, {
            add_to_cart: "add_to_cart",
        }),

        load_image: window.load_image,
    }
}
</script>
