<template lang="">
   <section class="b-g-light py-4" v-if="recent_visited_products && recent_visited_products.length">
        <div class="custom-container">
            <h2>Recent visited products</h2>
            <div class="mt-5">
                <div class="product_list" :class="{product_left: recent_visited_products.length < 5}">
                    <template v-for="product in recent_visited_products" :key="product.slug">
                        <ProductItem :product="product" />
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapState } from 'pinia';
import ProductItem from '../../../Components/ProductItem.vue';
import { useProductDetailsStore } from '../Store/product_details_store';
export default {
    components: {
        ProductItem,
    },
    computed: {
        ...mapState(useProductDetailsStore, [
            'recent_visited_products'
        ]),
    }
}
</script>
<style lang="">

</style>
