<template lang="">
    <div class="category_groups">
        <div v-for="item in brands" :key="item.id" class="category_group_item">
            <div>
                <img :src="load_image(item.image)" :alt="`etek.com.bd ${item.title} shop`" />
            </div>
            <div>
                {{ item.title }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapWritableState } from 'pinia';
import { home_main_store } from '../store/home_main_store';
export default {
    computed: {
        ...mapWritableState(home_main_store, [
            'brands'
        ])
    }
}
</script>
<style lang="">

</style>
