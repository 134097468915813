<template>

    <div class="mx-2">
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
        <skeleton :width="`100%`" :height="`80px`" :cs_class="`my-2`"></skeleton>
    </div>

</template>

<script>
import Skeleton from '../Skeleton.vue';
export default {
    components: { Skeleton }

}
</script>

<style></style>
